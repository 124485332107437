import { SiteSearchElement } from './SiteSearchElement';
import { ContactFormElement } from './ContactFormElement';

export default function defineWebComponents() {
  const tags: { name: string; element: any }[] = [
    { name: 'totem-contact-form', element: ContactFormElement },
    { name: 'totem-nav-search', element: SiteSearchElement },
  ];

  tags.forEach(({ name: tagName, element: Element }) => {
    if (!window.customElements.get(tagName)) {
      window.customElements.define(tagName, Element);
    }
  });
}
