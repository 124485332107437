import React from 'react';

import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
// import throttle from 'lodash/throttle';
// import debounce from 'lodash/debounce';

const Option = (props) => {
  const result = props.data;
  const far = result.distance > 25.0 ? <span className="badge bg-danger">FAR FROM YOU</span> : null;
  return (
    <div>
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            style={{ height: 64, width: 64 }}
            className="float-left mr-3"
            src={`${result.logo_url}-/preview/64x64/`}
          />
          <div className="notranslate">
            {result.name}
            <div className="small">
              {result.city_state} {far}
            </div>
          </div>
        </div>
      </components.Option>
    </div>
  );
};

const filterOptions = (_candidate, _input) => {
  return true;
};

type SiteSearchState = {
  isLoading: boolean;
  isPrimed: boolean;
  location: any;
  options: any;
};

export class SiteSearch extends React.Component<{}, SiteSearchState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPrimed: false,
      location: null,
      options: null,
    };
  }

  _urlFor = (query) => {
    let url = new URL('/api/v1/associations?', window.location.origin);
    if (query && typeof query === 'string') url.searchParams.append('q', query);
    if (this.state.location) {
      url.searchParams.append('lat', this.state.location.coords.latitude);
      url.searchParams.append('lon', this.state.location.coords.longitude);
    }

    return url.href;
  };

  _autocomplete = (query) => {
    this.setState({ isLoading: true });
    return fetch(this._urlFor(query))
      .then((res) => res.json())
      .then((res) => {
        this.setState({ isLoading: false, options: res.data });
        return res.data;
      })
      .catch(function(error) {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  // Could never get this working properly.
  // In the last iteration, it would fire the first and last network
  // calls, but the final result would never be updated into the input.
  // _searchCallback = debounce((query, callback) => {
  //   // this.setState({ isLoading: true });
  //   return fetch(this._urlFor(query))
  //     .then((res) => res.json())
  //     .then((res) => {
  //       // this.setState({ isLoading: false });
  //       console.log(query);
  //       console.log(res.data);
  //       // this.setState({ key: this.state.key + 1 });
  //       callback(res.data);
  //     })
  //     .catch(function(error) {
  //       // this.setState({ isLoading: false });
  //       console.log(error);
  //     });
  // }, 500);
  // handleInputChange = (newValue) => {
  //   //  const inputValue = newValue.replace(/\W/g, '');
  //   this.setState({ inputValue: newValue });
  //   return newValue;
  // };
  // _debouncedSearch = throttle((query, callback) => {
  //   fetch(this._urlFor(query))
  //     .then((res) => res.json())
  //     .then((json) => {
  //       console.log(json.data);
  //       this.setState({ isLoading: false });
  //       // return new Promise(() => result.data);
  //       callback(null, { options: json.data });
  //       // return result.data;
  //     })
  //     .catch((error) => callback(error, null));
  // }, 500);

  _primeResults = () => {
    if (!this.state.isPrimed) {
      this.setState({ isPrimed: true });
      if (navigator.geolocation) {
        this.setState({ isLoading: true });
        navigator.geolocation.getCurrentPosition(this._saveGeo);
      }
    }
  };
  _saveGeo = (position) => {
    console.log(position);
    this.setState({ location: position, isLoading: false });
    this._autocomplete(null);
  };
  render() {
    return (
      <div className="sitesearch w-100" style={{ minWidth: '300px' }}>
        <AsyncSelect
          cacheOptions
          defaultOptions={this.state.options}
          components={{ Option }}
          isLoading={this.state.isLoading}
          // loadOptions={this._searchCallback}
          loadOptions={this._autocomplete}
          onFocus={this._primeResults}
          placeholder="Find an association to join"
          onChange={(e) => (window.parent.location = e.url)}
          filterOption={filterOptions}
        />
      </div>
    );
  }
}
