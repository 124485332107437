import 'promise.prototype.finally/auto';

import React from 'react';
import ReactDOM from 'react-dom';

import Site from 'application/site';
import setupWebComponents from '../shared/webcomponents';
import utm from 'shared/site/utm'; // eslint-disable-line no-unused-vars

function renderApp(RootComponent) {
  const rootElement = document.getElementById('root');
  rootElement && ReactDOM.render(<RootComponent />, rootElement);
}

setupWebComponents();
renderApp(Site);
