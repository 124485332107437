import React from 'react';
import ReactDOM from 'react-dom';

import { SiteSearch } from 'shared/SiteSearch';

// Declare it as as web component
export class SiteSearchElement extends HTMLElement {
  connectedCallback() {
    ReactDOM.render(<SiteSearch />, this);
  }
}
