import React from 'react';
import ReactDOM from 'react-dom';

import { ContactForm } from 'shared/ContactForm';

export class ContactFormElement extends HTMLElement {
  connectedCallback() {
    ReactDOM.render(<ContactForm />, this);
  }
}
