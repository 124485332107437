import PropTypes from 'prop-types';
import React from 'react';

import { Button, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';

const validate = require('validate.js');

const availableFields = [
  'name',
  'email',
  'phone',
  'associationName',
  'associationWebsite',
  'associationSize',
  'comments',
];

const Field = ({
  id,
  type,
  placeholder,
  required,
  onChange,
  onBlur,
  isInvalid,
  invalidMsg,
  labelText,
  containerProps,
  labelProps,
  inputProps,
  children,
}) => {
  const defaultInputProps = {
    name: id,
    placeholder: placeholder ? placeholder : '',
    invalid: isInvalid,
    valid: false,
    id,
    type,
    onChange,
    onBlur,
    required,
  };
  const fieldLabelProps = Object.assign({}, labelProps, {
    for: id,
    className: labelProps.className + ` ${isInvalid ? 'text-danger' : ''}`,
  });
  const fieldInputProps = Object.assign({}, inputProps, defaultInputProps);
  return (
    <FormGroup {...containerProps}>
      {labelText && <Label {...fieldLabelProps}>{labelText}</Label>}
      {children ? <Input {...fieldInputProps}>{children}</Input> : <Input {...fieldInputProps} />}
      <FormFeedback className="pt-1">{invalidMsg}</FormFeedback>
    </FormGroup>
  );
};

Field.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email', 'tel', 'textarea', 'select', 'url']).isRequired,
  invalidMsg: PropTypes.string,
  isInvalid: PropTypes.bool,
  required: PropTypes.bool,
  containerProps: PropTypes.object,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  labelText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.bool, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

Field.defaultProps = {
  placeholder: false,
  labelText: false,
  children: false,
  required: false,
  isInvalid: false,
  invalidMsg: 'This field is required',
  containerProps: { className: 'mb-4' },
  labelProps: { className: 'font-weight-bold' },
  inputProps: {},
};

export class ContactForm extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.oneOf(availableFields)),
    requiredFields: PropTypes.arrayOf(PropTypes.oneOf(availableFields)),
  };

  static defaultProps = {
    title: 'Get in touch with TOTEM',
    fields: availableFields,
    requiredFields: availableFields,
  };

  constructor(props) {
    super(props);

    const { fields } = props;

    this.state = {
      submitted: false,
      fieldValues: [...fields].map((f) => ({
        field: f,
        isValid: true,
        value: '',
      })),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getFormField(field) {
    return [...this.state.fieldValues].filter((f) => f.field === field)[0];
  }

  validateField(field) {
    this.setState((prev) => ({
      fieldValues: [
        ...prev.fieldValues.filter((f) => f.field !== field),
        Object.assign({}, this.getFormField(field), { isValid: true }),
      ],
    }));
  }

  invalidateField(field) {
    this.setState((prev) => ({
      fieldValues: [
        ...prev.fieldValues.filter((f) => f.field !== field),
        Object.assign({}, this.getFormField(field), { isValid: false }),
      ],
    }));
  }

  // TODO extract validator function
  handleBlur(field, evt) {
    const inputValue = evt.target.value;
    switch (field) {
      case 'email': {
        const isEmailValid = validate.single(inputValue, {
          presence: true,
          email: true,
        });
        if (isEmailValid === undefined) {
          this.validateField(field);
        } else {
          this.invalidateField(field);
        }
        break;
      }
      default:
        if (inputValue.length === 0) {
          this.invalidateField(field);
        } else {
          this.validateField(field);
        }
        break;
    }
  }

  handleChange(field, evt) {
    const value = evt.target.value;
    this.setState((prev) => ({
      fieldValues: [
        ...prev.fieldValues.filter((f) => f.field !== field),
        Object.assign({}, this.getFormField(field), { value }),
      ],
    }));
  }

  handleSubmit = (e) => {
    console.log('handleSubmit()');

    const form = this.form;
    const url = form.action;
    const component = this;
    // if (form.checkValidity()) {
    e.preventDefault();
    // let formData = $(form).serialize(); //JSON();
    let formData = new FormData(form);

    if (!!formData.get('honeypot')) {
      console.error('hidden honeypot has value. preventing form submit');
      return;
    }

    function postFormData(additionalParams = {}) {
      Object.keys(additionalParams).forEach((key) => formData.append(key, additionalParams[key]));

      let requestBody = {
        method: 'post',
        body: new URLSearchParams(formData).toString(),
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
          // 'Content-Type': 'multipart/form-data',
          // 'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      };
      fetch(url, requestBody)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.status == '422') {
            throw data.errors;
          } else {
            component.setState({ submitted: true });
            // location.href = 'signup-submitted';
            if (
              window.totem &&
              window.totem.onContactFormSubmit &&
              typeof window.totem.onContactFormSubmit === 'function'
            ) {
              window.totem.onContactFormSubmit();
            }
          }
        })
        .catch(function (errorDic) {
          console.log(errorDic);
          // var errList = $('#registration-errors');
          // errList.empty();
          // Object.keys(errorDic).forEach(key => {
          //   errorDic[key].forEach(err => {
          //     if (key == 'name') {
          //       var li = $('<li/>')
          //         .text('company' + ' ' + err)
          //         .appendTo(errList);
          //     } else {
          //       var li = $('<li/>')
          //         .text(key + ' ' + err)
          //         .appendTo(errList);
          //     }
          //   });
          // });
        });
    }

    if (window.totem && window.totem.validateRecaptcha && typeof window.totem.validateRecaptcha === 'function')
      window.totem.validateRecaptcha(postFormData);
    else postFormData();

    // }
  };

  renderSuccess() {
    return <Row className="justify-content-center">Thank you for your inquiry. We will contact you shortly.</Row>;
  }
  render() {
    const { title, fields, requiredFields } = this.props;
    const { fieldValues } = this.state;
    const formIsValid = !fieldValues.map((f) => f.isValid).includes(false);
    const invalidFields = [...fieldValues].filter((f) => !f.isValid).map((f) => f.field);
    const utmFields = [
      'utm_source',
      'utm_campaign',
      'utm_medium',
      'utm_term',
      'utm_content',
      'referrer',
      'landing_page',
    ];

    return (
      <Container>
        <Row className="mb-5">
          <h3 className="h4 w-100 text-center font-weight-bold">{title}</h3>
        </Row>
        {this.state.submitted ? (
          this.renderSuccess()
        ) : (
          <Row className="justify-content-center">
            <Form
              className="col-12"
              style={{padding: 0}}
              action="/form"
              method="post"
              onSubmit={this.handleSubmit}
              innerRef={(form) => (this.form = form)}
            >
              <input id="honeypot" name="honeypot" type="text" style={{ display: 'none' }} />
              <input name="channel" value="contact" type="hidden" />

              {utmFields.map((utm) => (
                <input
                  key={utm}
                  name={utm}
                  placeholder={utm}
                  defaultValue={sessionStorage.getItem(utm)}
                  readOnly
                  type="hidden"
                />
              ))}

              {fields.includes('name') && (
                <Field
                  id="contactFullName"
                  type="text"
                  placeholder="Full Name"
                  labelText="Full Name"
                  onChange={(evt) => this.handleChange('name', evt)}
                  onBlur={(evt) => requiredFields.includes('name') && this.handleBlur('name', evt)}
                  isInvalid={invalidFields.includes('name')}
                  required={requiredFields.includes('name')}
                />
              )}
              {fields.includes('email') && (
                <Field
                  id="contactEmail"
                  type="email"
                  placeholder="email@yourprovider.com"
                  labelText="Email"
                  onChange={(evt) => this.handleChange('email', evt)}
                  onBlur={(evt) => requiredFields.includes('email') && this.handleBlur('email', evt)}
                  isInvalid={invalidFields.includes('email')}
                  required={requiredFields.includes('email')}
                />
              )}
              {fields.includes('phone') && (
                <Field
                  id="contactTel"
                  type="tel"
                  placeholder="(123) 456-7890"
                  labelText="Phone Number"
                  onChange={(evt) => this.handleChange('phone', evt)}
                  onBlur={(evt) => requiredFields.includes('phone') && this.handleBlur('phone', evt)}
                  isInvalid={invalidFields.includes('phone')}
                  invalidMsg="Please enter a valid phone number"
                  required={requiredFields.includes('phone')}
                />
              )}
              {fields.includes('associationName') && (
                <Field
                  id="contactAssociationName"
                  type="text"
                  placeholder="Association Name"
                  labelText="Association Name"
                  onChange={(evt) => this.handleChange('associationName', evt)}
                  onBlur={(evt) =>
                    requiredFields.includes('associationName') && this.handleBlur('associationName', evt)
                  }
                  isInvalid={invalidFields.includes('associationName')}
                  required={requiredFields.includes('associationName')}
                />
              )}
              {fields.includes('associationWebsite') && (
                <Field
                  id="contactAssociationWebsite"
                  type="url"
                  placeholder="association-website.com"
                  labelText="Association Website"
                  onChange={(evt) => this.handleChange('associationWebsite', evt)}
                  onBlur={(evt) =>
                    requiredFields.includes('associationWebsite') && this.handleBlur('associationWebsite', evt)
                  }
                  isInvalid={invalidFields.includes('associationWebsite')}
                  required={requiredFields.includes('associationWebsite')}
                />
              )}
              {fields.includes('associationSize') && (
                <Field
                  id="contactAssociationSize"
                  type="select"
                  labelText="How many members does your association have?"
                  onChange={(evt) => this.handleChange('associationSize', evt)}
                  onBlur={(evt) =>
                    requiredFields.includes('associationSize') && this.handleBlur('associationSize', evt)
                  }
                >
                  {['10 - 100', '100 - 1,000', '1,000 - 10,000', '10,000 - 100,000', '100,000 +'].map((opt) => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
                </Field>
              )}
              {fields.includes('comments') && (
                <Field
                  id="contactComments"
                  type="textarea"
                  inputProps={{ rows: 4 }}
                  labelText="Comments or Questions"
                  onChange={(evt) => this.handleChange('comments', evt)}
                  onBlur={(evt) => requiredFields.includes('comments') && this.handleBlur('comments', evt)}
                  isInvalid={invalidFields.includes('comments')}
                  required={requiredFields.includes('comments')}
                />
              )}
              <Button size="lg" color="primary" disabled={!formIsValid}>
                Send
              </Button>
            </Form>
          </Row>
        )}
      </Container>
    );
  }
}
